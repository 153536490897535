<!--
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-09-10 09:43:36
 * @LastEditTime: 2019-09-29 14:51:20
 * @LastEditors: Please set LastEditors
 销售模块   客户管理  销售管理  销售分析
 -->
<template>
  <div class="figureseven" v-if="you_xiaoping">
        <div class="xiaoping">
            <div class="tu2_con_left">
                <div class="tu2_con_left_tit">
                    <p class="xuanze_nianfen">
                        年份:
                        <el-select v-model="time" placeholder="请选择" @change="xuanze_nianfen_biao">
                            <el-option
                                v-for="item in year_list"
                                :key="item"
                                :label="item"
                                :value="item">
                            </el-option>
                        </el-select>
                    </p>
                    <div class="yierji">
                        <p @click="bumen_renyuan_dian(index)" v-for="(i,index) in bumen_renyuan_list" :key="index">
                            <img :src="bumen_renyuan==index?require('../../assets/huang_shixin.png'):require('../../assets/ccc_quan.png')" alt="">
                            {{i}}
                        </p>
                    </div>
                    <div v-if="time.length!=0" class="shaixuan_liebiao">
                        筛选<p class="shaixuan_box"></p>
                        <ul>
                            <li v-for="(i,index) in shaixuan_list2" @click="i.zhi=!i.zhi" :key="index"><img :src="i.zhi?require('../../assets/okblue.png'):require('../../assets/noccc.png')" alt=""><p>{{i.name}}</p></li>
                        </ul>
                    </div>
                </div>
                <!-- :span-method="bumen_renyuan==1?arraySpanMethod3:''" -->
                <img v-if="time==''" style="margin-top:0.1rem;height:86%;width:100%;" src="../../assets/shili_tu7-1.png" alt="">
                <div v-if="time!=''" class="innerbox">
                    <p>单位：元</p>
                    <el-table :data="neirong_list2"
                        border
                        max-height="300"
                        :cell-style="cellStyle_tiao"
                        :header-cell-style="headerCellStyle"
                        >
                        <el-table-column>
                            <template slot="header">
                                <div style="text-align:center;">
                                    部门任务责任书
                                </div>
                            </template>
                            <el-table-column v-for="(i, ind) in biaotou2"
                                :key="`col_${ind}`"
                                :prop="biaotou2[ind].con"
                                :label="i.name">
                                <!-- <template slot-scope="props">
                                </template> -->
                            </el-table-column>
                        </el-table-column>
                    </el-table>
                    <table class="laoye-tbody" border="1">
                        <tr class="laoye-tit">
                            <td :style="bumen_renyuan==1?'width:33%':''">合计</td>
                            <td :style="bumen_renyuan==1?'width:33%':''" v-if="bumen_renyuan==1"></td>
                            <td :style="bumen_renyuan==1?'width:33%;text-align:right;padding-right:0.14rem;':'text-align:right;padding-right:0.14rem;'">{{ren_heji}}</td>
                        </tr>
                    </table>
                </div>
            </div>
            <div class="tu2_con_right">
                <div class="tu2_con_right_tit">
                    <p class="xuanze_nianfen">
                        年份:{{time}}
                        <!-- 筛选 -->
                    </p>
                    <div class="yierji">
                        <p @click="yi_er_ji(index)" v-for="(i,index) in yi_er_list" :key="index">
                            <img :src="yi_er==index?require('../../assets/huang_shixin.png'):require('../../assets/ccc_quan.png')" alt="">
                            {{i}}
                        </p>
                    </div>
                    <div v-if="time.length!=0" class="shaixuan_liebiao">
                        筛选<p class="shaixuan_box"></p>
                        <ul>
                            <li v-for="(i,index) in shaixuan_list" @click="i.zhi=!i.zhi" :key="index"><img :src="i.zhi?require('../../assets/okblue.png'):require('../../assets/noccc.png')" alt=""><p>{{i.name}}</p></li>
                        </ul>
                    </div>
                </div>
                <img v-if="time==''" style="margin-top:0.1rem;height:86%;width:100%;" src="../../assets/shili_tu7-2.png" alt="">
                <div v-if="time!=''" class="innerbox">
                    <p>单位：元</p>
                    <el-table :data="neirong_list1"
                        border
                        max-height="300"
                        :cell-style="cellStyle_tiao"
                        :header-cell-style="headerCellStyle"
                        >
                        <el-table-column>
                            <template slot="header">
                                <div style="text-align:center;">
                                    产品任务责任书
                                </div>
                            </template>
                            <el-table-column v-for="(i, ind) in biaotou1"
                                :key="`col_${ind}`"
                                :prop="biaotou1[ind].con"
                                :label="i.name">
                                <!-- <template slot-scope="props">
                                </template> -->
                            </el-table-column>
                        </el-table-column>
                    </el-table>
                    <table class="laoye-tbody" border="1">
                        <tr class="laoye-tit">
                            <td>合计</td>
                            <td style="text-align:right;padding-right:0.14rem;">{{chanpin_heji}}</td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
        <div class="quanping" v-if="quan_zia">    <!--v-if="quan_zia==1"-->
            <div class="box">
                <div class="guanbi" @click="dianji_guanbi">X</div>
                <p class="box_text">销售责任书(七)</p>
                <div class="box_con">
                    <div class="tu2_con_left">
                        <div class="tu2_con_left_tit">
                            <p class="xuanze_nianfen">
                                年份:
                                <el-select v-model="time" placeholder="请选择" @change="xuanze_nianfen_biao">
                                    <el-option
                                        v-for="item in year_list"
                                        :key="item"
                                        :label="item"
                                        :value="item">
                                    </el-option>
                                </el-select>
                            </p>
                            <div class="yierji">
                                <p @click="bumen_renyuan_dian(index)" v-for="(i,index) in bumen_renyuan_list" :key="index">
                                    <img :src="bumen_renyuan==index?require('../../assets/huang_shixin.png'):require('../../assets/ccc_quan.png')" alt="">
                                    {{i}}
                                </p>
                            </div>
                            <div v-if="time.length!=0" class="shaixuan_liebiao">
                                筛选<p class="shaixuan_box"></p>
                                <ul>
                                    <li v-for="(i,index) in shaixuan_list2" @click="i.zhi=!i.zhi" :key="index"><img :src="i.zhi?require('../../assets/okblue.png'):require('../../assets/noccc.png')" alt=""><p>{{i.name}}</p></li>
                                </ul>
                            </div>
                        </div>
                        <!-- :span-method="bumen_renyuan==1?arraySpanMethod3:''" -->
                        <img v-if="time==''" style="margin-top:0.1rem;height:86%;width:100%;" src="../../assets/shili_tu7-1.png" alt="">
                        <div v-if="time!=''" class="innerbox">
                            <p>单位：元</p>
                            <el-table :data="neirong_list2"
                                border
                                max-height="580"
                                :cell-style="cellStyle_tiao"
                                :header-cell-style="headerCellStyle"
                                >
                                <el-table-column>
                                    <template slot="header">
                                        <div style="text-align:center;">
                                            部门任务责任书
                                        </div>
                                    </template>
                                    <el-table-column v-for="(i, ind) in biaotou2"
                                        :key="`col_${ind}`"
                                        :prop="biaotou2[ind].con"
                                        :label="i.name">
                                    </el-table-column>
                                </el-table-column>
                            </el-table>
                            <table class="laoye-tbody" border="1">
                                <tr class="laoye-tit">
                                    <td :style="bumen_renyuan==1?'width:33%':''">合计</td>
                                    <td :style="bumen_renyuan==1?'width:33%':''" v-if="bumen_renyuan==1"></td>
                                    <td :style="bumen_renyuan==1?'width:33%;text-align:right;padding-right:0.14rem;':'text-align:right;padding-right:0.14rem;'">{{ren_heji}}</td>
                                </tr>
                            </table>
                        </div>
                    </div>
                    <div class="tu2_con_right">
                        <div class="tu2_con_right_tit">
                            <p class="xuanze_nianfen">
                                年份:{{time}}
                                <!-- 筛选 -->
                            </p>
                            <div class="yierji">
                                <p @click="yi_er_ji(index)" v-for="(i,index) in yi_er_list" :key="index">
                                    <img :src="yi_er==index?require('../../assets/huang_shixin.png'):require('../../assets/ccc_quan.png')" alt="">
                                    {{i}}
                                </p>
                            </div>
                            <div v-if="time.length!=0" class="shaixuan_liebiao">
                                筛选<p class="shaixuan_box"></p>
                                <ul>
                                    <li v-for="(i,index) in shaixuan_list" @click="i.zhi=!i.zhi" :key="index"><img :src="i.zhi?require('../../assets/okblue.png'):require('../../assets/noccc.png')" alt=""><p>{{i.name}}</p></li>
                                </ul>
                            </div>
                        </div>
                        <img v-if="time==''" style="margin-top:0.1rem;height:86%;width:100%;" src="../../assets/shili_tu7-2.png" alt="">
                        <div v-if="time!=''" class="innerbox">
                            <p>单位：元</p>
                            <el-table :data="neirong_list1"
                                border
                                max-height="580"
                                :cell-style="cellStyle_tiao"
                                :header-cell-style="headerCellStyle"
                                >
                                <el-table-column>
                                    <template slot="header">
                                        <div style="text-align:center;">
                                            产品任务责任书
                                        </div>
                                    </template>
                                    <el-table-column v-for="(i, ind) in biaotou1"
                                        :key="`col_${ind}`"
                                        :prop="biaotou1[ind].con"
                                        :label="i.name">
                                        <!-- <template slot-scope="props">
                                        </template> -->
                                    </el-table-column>
                                </el-table-column>
                            </el-table>
                            <table class="laoye-tbody" border="1">
                                <tr class="laoye-tit">
                                    <td>合计</td>
                                    <td style="text-align:right;padding-right:0.14rem;">{{chanpin_heji}}</td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
  </div> 
</template>

<script>
import { generate_as_aim_resp, query_as_year_list } from '../../api/api.js'
export default {
  name: 'figureseven',
  data () {
    return {
      time: '',
      year_list: [],
      neirong_list1: [],
      neirong_list2: [],
      biaotou1: [],
      biaotou2: [],
      yi_er_list: ['一级', '明细'],
      yi_er: 0,
      bumen_renyuan_list: ['部门', '人员'],
      bumen_renyuan: 0,
      bumen: [],
      chanpin: [],
      chanpin2: [],
      renyuan: [],
      bumen_wz: [],
      chanpin_wz: [],
      chanpin2_wz: [],
      renyuan_wz: [],
      spanArr3: [],
      pos3: 0,
      indexArr3: [],
      quan_zia: false,
      you_xiaoping: true,
      shaixuan_list: [],
      shaixuan_list2: [],
      chanpin_heji: '0',
      ren_heji: '0'
    }
  },
  computed: {
  },
  props: {
    //   tuchu_chuanshu:String,
    //   tu7_quanzi:String,
    //   required: true,
  },
  watch: {
    shaixuan_list: {
      handler: function (val, oldVal) {
        this.dongtai_zhanshi()
        this.zidong_heji()
      },
      deep: true
    },
    shaixuan_list2: {
      handler: function (val, oldVal) {
        this.dongtai_zhanshi()
        this.zidong_heji()
      },
      deep: true
    }
  },
  created () {
    this.jichu()
  },
  mounted () {
  },
  methods: {
    //   基础信息
    jichu () {
      query_as_year_list({
        data: {
          ent_id: this.$ent_id(),
          aim_type: '6'
        }
      }).then(res => {
        console.log(res)
        if (res.data.code == 10237) {
          const date = JSON.parse(res.data.body.data)
          console.log(date)
          if (date.length != 0) {
            this.year_list = []
            for (let i = 0; i < date.length; i++) {
              this.year_list.push(date[i] + '年')
            }
            this.nianfen_xianshi_yincang = false
            date.forEach(it => {
              if (it == new Date().getFullYear()) {
                this.time = new Date().getFullYear() + '年'
                this.xuanze_nianfen_biao()
              }
            })
          } else {
            this.nianfen_xianshi_yincang = true
          }
        } else if (res.data.code == 10238) {}
      })
    },
    // 表头颜色
    headerCellStyle (row) { // 根据报警级别显示颜色
      return 'text-align:center;color:#1A2533;width:2.5rem;'
    },
    cellStyle_tiao (row) {
      if (row.column.label == '本年目标销售额') {
        return 'color:#4C4A4D;font-size:0.12rem;text-align:right;width:2.5rem;'
      } else {
        return 'color:#4C4A4D;font-size:0.12rem;text-align:center;width:2.5rem;'
      }
    },
    getSpanArr3 (data) {
      for (var i = 0; i < data.length; i++) {
        if (i === 0) {
          this.spanArr3.push(1)
          this.pos3 = 0
        } else {
          // 判断当前元素与上一个元素是否相同（line为标记）
          if (data[i].dept_name === data[i - 1].dept_name) {
            this.spanArr3[this.pos3] += 1
            this.spanArr3.push(0)
          } else {
            this.spanArr3.push(1)
            this.pos3 = i
          }
        }
      }
    },
    // 处理行的合并
    arraySpanMethod3 ({ row, column, rowIndex, columnIndex }) {
      if (this.indexArr3.includes(rowIndex)) {
        if (columnIndex === 0) {
          return {
            rowspan: 1,
            colspan: 3
          }
        } else if (columnIndex === 1) {
          return {
            rowspan: 0,
            colspan: 0
          }
        }
      }
      if (columnIndex === 0) {
        const _row = this.spanArr3[rowIndex]
        const _col = _row > 0 ? 1 : 0
        return {
          rowspan: _row,
          colspan: _col
        }
      }
    },
    // 选择年份
    xuanze_nianfen_biao () {
      generate_as_aim_resp({
        data: {
          ent_id: this.$ent_id(),
          year: this.time.slice(0, 4)
        }
      }).then(res => {
        console.log(res)
        if (res.data.code == 200) {
          const chanpin = JSON.parse(res.data.body.cat_list)
          const bumen = JSON.parse(res.data.body.dept_list)
          const chanpin2 = JSON.parse(res.data.body.prod_list)
          const renyuan = JSON.parse(res.data.body.staff_list)
          this.chanpin = chanpin
          this.chanpin2 = chanpin2
          this.bumen = bumen
          this.renyuan = renyuan
          this.chanpin_wz = chanpin
          this.chanpin_wz.map(item => {
            this.$set(item, 'zhi', true)
            this.$set(item, 'name', item.cat_name)
          })
          this.chanpin2_wz = chanpin2
          this.chanpin2_wz.map(item => {
            this.$set(item, 'zhi', true)
            this.$set(item, 'name', item.prod_name)
          })
          this.bumen_wz = bumen
          this.bumen_wz.map(item => {
            this.$set(item, 'zhi', true)
            this.$set(item, 'name', item.dept_name)
          })
          this.renyuan_wz = renyuan
          this.renyuan_wz.map(item => {
            this.$set(item, 'zhi', true)
            this.$set(item, 'name', item.staff_name)
          })
          this.chushi1()
          this.spanArr3 = [],
          this.pos3 = 0,
          this.chushi2()
          if (this.yi_er == 0) {
            this.shaixuan_list = this.chanpin_wz
          } else if (this.yi_er == 1) {
            this.shaixuan_list = this.chanpin2_wz
          }
          if (this.bumen_renyuan == 0) {
            this.shaixuan_list2 = this.bumen_wz
          } else if (this.bumen_renyuan == 1) {
            this.shaixuan_list2 = this.renyuan_wz
          }
        } else if (res.data.code == 500) {}
      })
      this.$emit('tu7_nianfen', this.time)
    },
    // 一级与明细点击
    yi_er_ji (index) {
      this.yi_er = index
      if (this.yi_er == 0) {
        this.chushi1()
      } else if (this.yi_er == 1) {
        this.biaotou1 = [
          {
            name: '明细产品',
            con: 'prod_name'
          },
          {
            name: '本年目标销售额',
            con: 'amt_aim_final'
          }
        ]
        this.neirong_list1 = []
        if (this.chanpin2.length != 0) {
          for (let i = 0; i < this.chanpin2.length; i++) {
            this.neirong_list1.push({
              prod_name: this.chanpin2[i].prod_name,
              amt_aim_final: Number(this.chanpin2[i].amt_aim_final)
            })
          }
        }
      }
      if (this.yi_er == 0) {
        this.shaixuan_list = this.chanpin_wz
      } else if (this.yi_er == 1) {
        this.shaixuan_list = this.chanpin2_wz
      }
    },
    chushi1 () {
      this.biaotou1 = [
        {
          name: '一级产品',
          con: 'cat_name'
        },
        {
          name: '本年目标销售额',
          con: 'amt_aim'
        }
      ]
      this.neirong_list1 = []
      if (this.chanpin.length != 0) {
        for (let i = 0; i < this.chanpin.length; i++) {
          this.neirong_list1.push({
            cat_name: this.chanpin[i].cat_name,
            amt_aim: Number(this.chanpin[i].amt_aim).toFixed(0)
          })
        }
      }
    },
    //  部门与人员点击
    bumen_renyuan_dian (index) {
      this.bumen_renyuan = index
      if (this.bumen_renyuan == 0) {
        this.chushi2()
      } else if (this.bumen_renyuan == 1) {
        this.biaotou2 = [
          {
            name: '部门',
            con: 'dept_name'
          },
          {
            name: '人员',
            con: 'staff_name'
          },
          {
            name: '本年目标销售额',
            con: 'amt_aim'
          }
        ]
        this.neirong_list2 = []
        if (this.renyuan.length != 0) {
          for (let i = 0; i < this.renyuan.length; i++) {
            this.neirong_list2.push({
              dept_name: this.renyuan[i].dept_name,
              amt_aim: Number(this.renyuan[i].amt_aim).toFixed(0),
              staff_name: this.renyuan[i].staff_name
            })
          }
        }
        this.spanArr3 = [],
        this.pos3 = 0,
        this.getSpanArr3(this.neirong_list2)
      }
      if (this.bumen_renyuan == 0) {
        this.shaixuan_list2 = this.bumen_wz
      } else if (this.bumen_renyuan == 1) {
        this.shaixuan_list2 = this.renyuan_wz
      }
    },
    chushi2 () {
      this.biaotou2 = [
        {
          name: '部门',
          con: 'dept_name'
        },
        {
          name: '本年目标销售额',
          con: 'amt_aim'
        }
      ]
      this.neirong_list2 = []
      if (this.bumen.length != 0) {
        for (let i = 0; i < this.bumen.length; i++) {
          this.neirong_list2.push({
            dept_name: this.bumen[i].dept_name,
            amt_aim: Number(this.bumen[i].amt_aim).toFixed(0)
          })
        }
      }
    },
    // 点击关闭
    dianji_guanbi () {
      // this.$router.push('/aspreackeven')
      // this.quan_zia=''
      // this.$emit('nianfen_fu', '1')
      // sessionStorage.setItem("pc_mlbb_mubiao_biao", '-1');
      if (this.biaoge_nei) {
        this.quan_zia = false
        this.you_xiaoping = false
      } else {
        this.quan_zia = false
      }
      // this.yierji_panduan2_da=true
    },
    biaoge_dakai () {
      this.you_xiaoping = false
    },
    dakai_quanping (data) {
      console.log(data)
      this.you_xiaoping = true
      this.time = data.time
      this.quan_zia = data.chutu
      this.biaoge_nei = data.biaoge_nei
      this.xuanze_nianfen_biao()
      // if(data.chutu){
      // this.nianfen_zhi()
      // this.nianfen_zhi2()
      // }else{
      //     // this.nianfen_zhi2()
      // }
    },
    dongtai_zhanshi () {
      if (this.yi_er == 0) {
        const list = []
        for (let i = 0; i < this.shaixuan_list.length; i++) {
          if (this.shaixuan_list[i].zhi) {
            list.push(this.shaixuan_list[i])
          }
        }
        this.biaotou1 = [
          {
            name: '一级产品',
            con: 'cat_name'
          },
          {
            name: '本年目标销售额',
            con: 'amt_aim'
          }
        ]
        this.neirong_list1 = []
        for (let i = 0; i < list.length; i++) {
          this.neirong_list1.push({
            cat_name: list[i].cat_name,
            amt_aim: Number(list[i].amt_aim).toFixed(0)
          })
        }
      } else if (this.yi_er == 1) {
        const list = []
        for (let i = 0; i < this.shaixuan_list.length; i++) {
          if (this.shaixuan_list[i].zhi) {
            list.push(this.shaixuan_list[i])
          }
        }
        this.biaotou1 = [
          {
            name: '明细产品',
            con: 'prod_name'
          },
          {
            name: '本年目标销售额',
            con: 'amt_aim_final'
          }
        ]
        this.neirong_list1 = []
        for (let i = 0; i < list.length; i++) {
          this.neirong_list1.push({
            prod_name: list[i].prod_name,
            amt_aim_final: Number(list[i].amt_aim_final).toFixed(0)
          })
        }
      }
      if (this.bumen_renyuan == 0) {
        const list = []
        for (let i = 0; i < this.shaixuan_list2.length; i++) {
          if (this.shaixuan_list2[i].zhi) {
            list.push(this.shaixuan_list2[i])
          }
        }
        this.biaotou2 = [
          {
            name: '部门',
            con: 'dept_name'
          },
          {
            name: '本年目标销售额',
            con: 'amt_aim'
          }
        ]
        this.neirong_list2 = []
        for (let i = 0; i < list.length; i++) {
          this.neirong_list2.push({
            dept_name: list[i].dept_name,
            amt_aim: Number(list[i].amt_aim).toFixed(0)
          })
        }
      } else if (this.bumen_renyuan == 1) {
        const list = []
        for (let i = 0; i < this.shaixuan_list2.length; i++) {
          if (this.shaixuan_list2[i].zhi) {
            list.push(this.shaixuan_list2[i])
          }
        }
        this.biaotou2 = [
          {
            name: '部门',
            con: 'dept_name'
          },
          {
            name: '人员',
            con: 'staff_name'
          },
          {
            name: '本年目标销售额',
            con: 'amt_aim'
          }
        ]
        this.neirong_list2 = []
        for (let i = 0; i < list.length; i++) {
          this.neirong_list2.push({
            dept_name: list[i].dept_name,
            amt_aim: Number(list[i].amt_aim).toFixed(0),
            staff_name: list[i].staff_name
          })
        }
        this.spanArr3 = [],
        this.pos3 = 0,
        this.getSpanArr3(this.neirong_list2)
      }
    },
    zidong_heji () {
      console.log(this.neirong_list1)
      console.log(this.neirong_list2)
      let chanpin_heji = ''
      let ren_heji = ''
      if (this.yi_er == 0) {
        this.neirong_list1.forEach(item => {
          chanpin_heji = Number(chanpin_heji) + Number(item.amt_aim)
        })
      } else {
        this.neirong_list1.forEach(item => {
          chanpin_heji = Number(chanpin_heji) + Number(item.amt_aim_final)
        })
      }
      this.neirong_list2.forEach(item => {
        ren_heji = Number(ren_heji) + Number(item.amt_aim)
      })
      console.log(chanpin_heji)
      console.log(ren_heji)
      this.chanpin_heji = chanpin_heji
      this.ren_heji = ren_heji
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.figureseven {
    height:100%;
    width:100%;
    // position: relative;
    .xiaoping{
        height:100%;
        width:100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .tu2_con_left{
            height:3.88rem;
            width:59%;
            background:#fff;
            border-radius: 0.04rem;
            position: relative;
            .tu2_con_left_tit{
                display: flex;
                justify-content: space-between;
                padding-top:0.18rem;
                margin:0 0.16rem;
                .xuanze_nianfen{
                    font-size: 0.12rem;
                    color:#4C4A4D;
                    display: flex;
                    align-items: center;
                }
                .yierji{
                    display: flex;
                    align-items: center;
                    p{
                        font-size: 0.12rem;
                        color:#4C4A4D;
                        display: flex;
                        align-items: center;
                        cursor: pointer;
                        img{
                            height:0.19rem;
                            width:0.19rem;
                            margin-right: 0.07rem;
                        }
                        margin-left: 0.1rem;
                        &:nth-child(1){
                            margin-left: 0;
                        }
                    }
                }
                .paixu{
                    display: flex;
                    align-items: center;
                    font-size: 0.13rem;
                    color:#4C4A4D;
                    cursor: pointer;
                    img{
                        height:0.18rem;
                        width:0.18rem;
                    }
                }
                .shaixuan_liebiao{
                    position: relative;
                    display: flex;
                    align-items: center;
                    .shaixuan_box{
                        width:0.8rem;
                        height:0.3rem;
                        padding: 0 0.1rem;
                        text-align: center;
                        border:0.01rem solid #ccc;
                        border-radius: 0.03rem;
                        text-overflow: ellipsis;  /* ellipsis:显示省略符号来代表被修剪的文本  string:使用给定的字符串来代表被修剪的文本*/ 
                        white-space: nowrap;   /* nowrap:规定段落中的文本不进行换行   */ 
                        overflow: hidden; /*超出部分隐藏*/
                        margin-left: 0.1rem;
                    }
                    &:hover{
                        ul{
                            display: block;
                        }
                    }
                    ul{
                        display: none;
                        position: absolute;
                        top:0.3rem;
                        width: 1.34rem;
                        height:2.09rem;
                        z-index: 999;
                        overflow-y:auto;
                        border:0.01rem solid #ccc;
                        background: #fff;
                        &::-webkit-scrollbar {
                            width: 0.2rem;
                            height: 0.08rem;
                        }
                        
                        &::-webkit-scrollbar-thumb {
                            border-radius: 0.12rem;
                            border: 0.06rem solid rgba(0, 0, 0, 0);
                            box-shadow: 0.08rem 0 0 #A5ADB7 inset;
                        }
                        
                        &::-webkit-scrollbar-thumb:hover {
                            box-shadow: 0.08rem 0 0 #4A4A4A inset;
                        }
                        li{
                            padding: 0.05rem;
                            margin: 0 0.16rem;
                            display: flex;
                            align-items: center;
                            cursor: pointer;
                            &:hover{
                                background: rgb(173, 173, 173);
                            }
                            img{
                                height:0.16rem;
                                width:0.16rem;
                                margin-right: 0.03rem;
                            }
                            p{
                                font-size: 0.12rem;
                                color:#4C4A4D;
                                width: 0.9rem;
                                text-overflow: ellipsis;  /* ellipsis:显示省略符号来代表被修剪的文本  string:使用给定的字符串来代表被修剪的文本*/ 
                                white-space: nowrap;   /* nowrap:规定段落中的文本不进行换行   */ 
                                overflow: hidden; /*超出部分隐藏*/
                            }
                        }
                    }
                }
            }
            .tu_text{
                position: absolute;
                top:0.55rem;
                left: 0.32rem;
                display: flex;
                align-items: center;
                justify-content: space-between;
                width:76.5%;
                p{
                    &:nth-child(1){
                        span{
                            font-size: 0.1rem;
                            color: #4C4A4D;
                        }
                    }
                    &:nth-child(2){
                        width:93%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        font-size: 0.12rem;
                        color: #4C4A4D;
                        span{
                            display: inline-block;
                            margin: 0 12%;
                        }
                    }
                }
            }
        }
        .innerbox{
            margin:0 0.16rem;
            margin-top:0.2rem;
            overflow-y: auto;
            color: #000;
            font-size: .7rem;
            font-family: "\5FAE\8F6F\96C5\9ED1",Helvetica,"黑体",Arial,Tahoma;
            height: 82%;
        }
        .innerbox::-webkit-scrollbar {
            width: 0.04rem;
        }
        .innerbox::-webkit-scrollbar-thumb {
            border-radius: 0.1rem;
            -webkit-box-shadow: inset 0 0 0.05rem rgba(0,0,0,0.2);
            background: rgba(0,0,0,0.2);
        }
        .innerbox::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 0.05rem rgba(0,0,0,0.2);
            border-radius: 0;
            background: rgba(0,0,0,0.1);
        }
        .tu2_con_right{
            height:3.88rem;
            width:39%;
            background:#fff;
            border-radius: 0.04rem;
            .tu2_con_right_tit{
                display: flex;
                justify-content: space-between;
                padding-top:0.18rem;
                margin:0 0.16rem;
                .shaixuan_liebiao{
                    position: relative;
                    display: flex;
                    align-items: center;
                    .shaixuan_box{
                        width:0.8rem;
                        height:0.3rem;
                        padding: 0 0.1rem;
                        text-align: center;
                        border:0.01rem solid #ccc;
                        border-radius: 0.03rem;
                        text-overflow: ellipsis;  /* ellipsis:显示省略符号来代表被修剪的文本  string:使用给定的字符串来代表被修剪的文本*/ 
                        white-space: nowrap;   /* nowrap:规定段落中的文本不进行换行   */ 
                        overflow: hidden; /*超出部分隐藏*/
                        margin-left: 0.1rem;
                    }
                    &:hover{
                        ul{
                            display: block;
                        }
                    }
                    ul{
                        display: none;
                        position: absolute;
                        top:0.3rem;
                        width: 1.34rem;
                        height:2.09rem;
                        z-index: 999;
                        overflow-y:auto;
                        border:0.01rem solid #ccc;
                        background: #fff;
                        &::-webkit-scrollbar {
                            width: 0.2rem;
                            height: 0.08rem;
                        }
                        
                        &::-webkit-scrollbar-thumb {
                            border-radius: 0.12rem;
                            border: 0.06rem solid rgba(0, 0, 0, 0);
                            box-shadow: 0.08rem 0 0 #A5ADB7 inset;
                        }
                        
                        &::-webkit-scrollbar-thumb:hover {
                            box-shadow: 0.08rem 0 0 #4A4A4A inset;
                        }
                        li{
                            padding: 0.05rem;
                            margin: 0 0.16rem;
                            display: flex;
                            align-items: center;
                            cursor: pointer;
                            &:hover{
                                background: rgb(173, 173, 173);
                            }
                            img{
                                height:0.16rem;
                                width:0.16rem;
                                margin-right: 0.03rem;
                            }
                            p{
                                font-size: 0.12rem;
                                color:#4C4A4D;
                                width: 0.9rem;
                                text-overflow: ellipsis;  /* ellipsis:显示省略符号来代表被修剪的文本  string:使用给定的字符串来代表被修剪的文本*/ 
                                white-space: nowrap;   /* nowrap:规定段落中的文本不进行换行   */ 
                                overflow: hidden; /*超出部分隐藏*/
                            }
                        }
                    }
                }
                .xuanze_nianfen{
                    font-size: 0.12rem;
                    color:#4C4A4D;
                    display: flex;
                    align-items: center;
                }
                .yierji{
                    display: flex;
                    align-items: center;
                    p{
                        font-size: 0.12rem;
                        color:#4C4A4D;
                        display: flex;
                        align-items: center;
                        cursor: pointer;
                        img{
                            height:0.19rem;
                            width:0.19rem;
                            margin-right: 0.07rem;
                        }
                        margin-left: 0.1rem;
                        &:nth-child(1){
                            margin-left: 0;
                        }
                    }
                }
                .paixu{
                    display: flex;
                    align-items: center;
                    font-size: 0.13rem;
                    color:#4C4A4D;
                    cursor: pointer;
                    img{
                        height:0.18rem;
                        width:0.18rem;
                    }
                }
            }
            .tu2_right_danwei{
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding-top:0.29rem;
                margin:0 0.16rem;
                span{
                    color:#4C4A4D;
                    &:nth-child(1){
                        font-size: 0.1rem;
                    }
                    &:nth-child(2){
                        font-size: 0.12rem;
                    }
                }
            }
            #myCharts6{
                margin: 0 auto;
            }
            #myCharts7{
                margin: 0 auto;
            }
        }
    }
    .quanping{
        height:100%;
        width:100%;
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        overflow: auto;
        margin: 0;
        z-index:999;
        background: rgba(0,0,0,0.8);
        .box{
            position: relative;
            height:90%;
            width: 14rem;
            left:50%;
            top:5%;
            margin-left:-8rem;
            background: #F1F3F9;
            .box_text{
                position: absolute;
                font-size: 0.17rem;
                color:#4C4A4D;
                left:5%;
                top:3%;
            }
            .guanbi{
                position: absolute;
                top:-0.2rem;
                right:-0.2rem;
                height:0.6rem;
                width: 0.6rem;
                border-radius: 50%;
                background: #fc6a6a;
                color:#fff;
                text-align: center;
                font-size: 0.3rem;
                line-height: 0.6rem;
                box-shadow:0 0 0.05rem 0.05rem rgba(27, 27, 27, 0.5);
                cursor: pointer;
            }
            .box_con{
                position: relative;
                height:86%;
                width: 90%;
                left:5%;
                top:7%;
                background: #fff;
                display: flex;
                justify-content: space-between;
                align-items: center;
                .tu2_con_left{
                    height:7.11rem;
                    width:59%;
                    background:#fff;
                    border-radius: 0.04rem;
                    position: relative;
                    .tu2_con_left_tit{
                        display: flex;
                        justify-content: space-between;
                        padding-top:0.18rem;
                        margin:0 0.16rem;
                        .shaixuan_liebiao{
                            position: relative;
                            display: flex;
                            align-items: center;
                            .shaixuan_box{
                                width:0.8rem;
                                height:0.3rem;
                                padding: 0 0.1rem;
                                text-align: center;
                                border:0.01rem solid #ccc;
                                border-radius: 0.03rem;
                                text-overflow: ellipsis;  /* ellipsis:显示省略符号来代表被修剪的文本  string:使用给定的字符串来代表被修剪的文本*/ 
                                white-space: nowrap;   /* nowrap:规定段落中的文本不进行换行   */ 
                                overflow: hidden; /*超出部分隐藏*/
                                margin-left: 0.1rem;
                            }
                            &:hover{
                                ul{
                                    display: block;
                                }
                            }
                            ul{
                                display: none;
                                position: absolute;
                                top:0.3rem;
                                width: 1.34rem;
                                height:2.09rem;
                                z-index: 999;
                                overflow-y:auto;
                                border:0.01rem solid #ccc;
                                // background: #fff;
                                // &::-webkit-scrollbar {
                                //     width: 0.2rem;
                                //     height: 0.08rem;
                                // }
                                
                                // &::-webkit-scrollbar-thumb {
                                //     border-radius: 0.12rem;
                                //     border: 0.06rem solid rgba(0, 0, 0, 0);
                                //     box-shadow: 0.08rem 0 0 #A5ADB7 inset;
                                // }
                                
                                // &::-webkit-scrollbar-thumb:hover {
                                //     box-shadow: 0.08rem 0 0 #4A4A4A inset;
                                // }
                                li{
                                    padding: 0.05rem;
                                    margin: 0 0.16rem;
                                    display: flex;
                                    align-items: center;
                                    cursor: pointer;
                                    &:hover{
                                        background: rgb(173, 173, 173);
                                    }
                                    img{
                                        height:0.16rem;
                                        width:0.16rem;
                                        margin-right: 0.03rem;
                                    }
                                    p{
                                        font-size: 0.12rem;
                                        color:#4C4A4D;
                                        width: 0.9rem;
                                        text-overflow: ellipsis;  /* ellipsis:显示省略符号来代表被修剪的文本  string:使用给定的字符串来代表被修剪的文本*/ 
                                        white-space: nowrap;   /* nowrap:规定段落中的文本不进行换行   */ 
                                        overflow: hidden; /*超出部分隐藏*/
                                    }
                                }
                            }
                        }
                        .xuanze_nianfen{
                            font-size: 0.12rem;
                            color:#4C4A4D;
                            display: flex;
                            align-items: center;
                        }
                        .yierji{
                            display: flex;
                            align-items: center;
                            p{
                                font-size: 0.12rem;
                                color:#4C4A4D;
                                display: flex;
                                align-items: center;
                                cursor: pointer;
                                img{
                                    height:0.19rem;
                                    width:0.19rem;
                                    margin-right: 0.07rem;
                                }
                                margin-left: 0.1rem;
                                &:nth-child(1){
                                    margin-left: 0;
                                }
                            }
                        }
                        .paixu{
                            display: flex;
                            align-items: center;
                            font-size: 0.13rem;
                            color:#4C4A4D;
                            cursor: pointer;
                            img{
                                height:0.18rem;
                                width:0.18rem;
                            }
                        }
                    }
                    .tu_text{
                        position: absolute;
                        top:0.55rem;
                        left: 0.32rem;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        width:76.5%;
                        p{
                            &:nth-child(1){
                                span{
                                    font-size: 0.1rem;
                                    color: #4C4A4D;
                                }
                            }
                            &:nth-child(2){
                                width:93%;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                font-size: 0.12rem;
                                color: #4C4A4D;
                                span{
                                    display: inline-block;
                                    margin: 0 12%;
                                }
                            }
                        }
                    }
                }
                .innerbox{
                    margin:0 0.16rem;
                    margin-top:0.2rem;
                    overflow-y: auto;
                    color: #000;
                    font-size: .7rem;
                    font-family: "\5FAE\8F6F\96C5\9ED1",Helvetica,"黑体",Arial,Tahoma;
                    height: 82%;
                }
                .innerbox::-webkit-scrollbar {
                    width: 0.04rem;
                }
                .innerbox::-webkit-scrollbar-thumb {
                    border-radius: 0.1rem;
                    -webkit-box-shadow: inset 0 0 0.05rem rgba(0,0,0,0.2);
                    background: rgba(0,0,0,0.2);
                }
                .innerbox::-webkit-scrollbar-track {
                    -webkit-box-shadow: inset 0 0 0.05rem rgba(0,0,0,0.2);
                    border-radius: 0;
                    background: rgba(0,0,0,0.1);
                }
                .tu2_con_right{
                    height:7.11rem;
                    width:39%;
                    background:#fff;
                    border-radius: 0.04rem;
                    .tu2_con_right_tit{
                        display: flex;
                        justify-content: space-between;
                        padding-top:0.18rem;
                        margin:0 0.16rem;
                        .shaixuan_liebiao{
                            position: relative;
                            display: flex;
                            align-items: center;
                            .shaixuan_box{
                                width:0.8rem;
                                height:0.3rem;
                                padding: 0 0.1rem;
                                text-align: center;
                                border:0.01rem solid #ccc;
                                border-radius: 0.03rem;
                                text-overflow: ellipsis;  /* ellipsis:显示省略符号来代表被修剪的文本  string:使用给定的字符串来代表被修剪的文本*/ 
                                white-space: nowrap;   /* nowrap:规定段落中的文本不进行换行   */ 
                                overflow: hidden; /*超出部分隐藏*/
                                margin-left: 0.1rem;
                            }
                            &:hover{
                                ul{
                                    display: block;
                                }
                            }
                            ul{
                                display: none;
                                position: absolute;
                                top:0.3rem;
                                width: 1.34rem;
                                height:2.09rem;
                                z-index: 999;
                                // overflow-y:auto;
                                border:0.01rem solid #ccc;
                                background: #fff;
                                // &::-webkit-scrollbar {
                                //     width: 0.2rem;
                                //     height: 0.08rem;
                                // }
                                
                                // &::-webkit-scrollbar-thumb {
                                //     border-radius: 0.12rem;
                                //     border: 0.06rem solid rgba(0, 0, 0, 0);
                                //     box-shadow: 0.08rem 0 0 #A5ADB7 inset;
                                // }
                                
                                // &::-webkit-scrollbar-thumb:hover {
                                //     box-shadow: 0.08rem 0 0 #4A4A4A inset;
                                // }
                                li{
                                    padding: 0.05rem;
                                    margin: 0 0.16rem;
                                    display: flex;
                                    align-items: center;
                                    cursor: pointer;
                                    &:hover{
                                        background: rgb(173, 173, 173);
                                    }
                                    img{
                                        height:0.16rem;
                                        width:0.16rem;
                                        margin-right: 0.03rem;
                                    }
                                    p{
                                        font-size: 0.12rem;
                                        color:#4C4A4D;
                                        width: 0.9rem;
                                        text-overflow: ellipsis;  /* ellipsis:显示省略符号来代表被修剪的文本  string:使用给定的字符串来代表被修剪的文本*/ 
                                        white-space: nowrap;   /* nowrap:规定段落中的文本不进行换行   */ 
                                        overflow: hidden; /*超出部分隐藏*/
                                    }
                                }
                            }
                        }
                        .xuanze_nianfen{
                            font-size: 0.12rem;
                            color:#4C4A4D;
                            display: flex;
                            align-items: center;
                        }
                        .yierji{
                            display: flex;
                            align-items: center;
                            p{
                                font-size: 0.12rem;
                                color:#4C4A4D;
                                display: flex;
                                align-items: center;
                                cursor: pointer;
                                img{
                                    height:0.19rem;
                                    width:0.19rem;
                                    margin-right: 0.07rem;
                                }
                                margin-left: 0.1rem;
                                &:nth-child(1){
                                    margin-left: 0;
                                }
                            }
                        }
                        .paixu{
                            display: flex;
                            align-items: center;
                            font-size: 0.13rem;
                            color:#4C4A4D;
                            cursor: pointer;
                            img{
                                height:0.18rem;
                                width:0.18rem;
                            }
                        }
                    }
                    .tu2_right_danwei{
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        padding-top:0.29rem;
                        margin:0 0.16rem;
                        span{
                            color:#4C4A4D;
                            &:nth-child(1){
                                font-size: 0.1rem;
                            }
                            &:nth-child(2){
                                font-size: 0.12rem;
                            }
                        }
                    }
                    #myCharts6{
                        margin: 0 auto;
                    }
                    #myCharts7{
                        margin: 0 auto;
                    }
                }
            }
        }
    }
    .laoye-tbody{
        border:0.01rem solid #888888;
        width:100%;
        border-collapse:collapse;
        tr{
            td{
                height:0.49rem;
                width:48%;
                text-align: center;
                line-height: 0.49rem !important;
                font-size: 0.14rem;
                color:#4C4A4D;
                position: relative;
            }
        }
    }
    @media screen and (max-width: 1600px) {
        .quanping {
            width:1600px;
        }
    }
}
</style>
